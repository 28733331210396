import * as React from "react"
import PageLayout from "../../components/pageLayout";
import Seo from "../../components/seo";

const OpplaeringPage = () => {
    return <PageLayout>
        <Seo title={"Opplæring"}/>
        <div className="relative py-16 bg-secondary-light overflow-hidden">
            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <h1>
            <span className="block text-base text-center text-primary font-semibold tracking-wide uppercase">
              Tjenester:
            </span>
                        <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-100 sm:text-4xl">
              Opplæring/Kurs
            </span>
                    </h1>
                </div>
                <div className="mt-6 prose prose-primary prose-lg text-gray-200 mx-auto">
                    <p>
                        En god sikkerhetskultur er ryggraden i en vellykket operasjon. Vi tilbyr skreddersydde kurs og opplæring i bruk av alt utstyr og programvare som vi leverer, samt utvikler sertifiseringssystemer og opplæringsplattformer for enkel kontroll på operasjon. Kurs og opplæring kan avholdes på kundes lokasjon eller bli produsert og levert digitalt.
                    </p>
                </div>
            </div>
        </div>
    </PageLayout>
}

export default OpplaeringPage